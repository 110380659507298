import React, { useState } from 'react'
import {
  Box,
  Bullet,
  Button,
  Collapsible,
  Collapsibles,
  GridList,
  Heading,
  Help,
  Inline,
  Lead,
  Link,
  List,
  ListItem,
  MailIcon,
  Paragraph,
  Strong,
  ThumbDownIcon,
  ThumbUpIcon
} from '@te-digi/styleguide'

import { Link as InnerLink } from '../../components/Link'
import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { Playground } from '../../components/Playground'
import { Section } from '../../components/Section'
import { SectionHeading } from '../../components/SectionHeading'
import { CompleteFormExample } from '../../examples/react/CompleteFormExample'
import { FormLayoutWrapper } from '../../components/FormLayoutWrapper'
import LabelHTMLExample from '../../examples/html/LabelHTMLExample'
import { CollapsibleRequiredMissingExample } from '../../examples/react/CollapsibleRequiredMissingExample'
const Page = () => {
  const [isOpen1, setIsOpen1] = useState(false)
  const [isOpen2, setIsOpen2] = useState(false)
  const [isOpen3, setIsOpen3] = useState(false)
  const [isOpen4, setIsOpen4] = useState(false)
  const [isOpen5, setIsOpen5] = useState(false)
  const [isOpen6, setIsOpen6] = useState(false)
  const [isOpen7, setIsOpen7] = useState(false)
  const [isOpen8, setIsOpen8] = useState(false)
  const [isOpen9, setIsOpen9] = useState(false)
  const [isOpen10, setIsOpen10] = useState(false)
  const [isOpen11, setIsOpen11] = useState(false)

  return (
    <Content heading="Saavutettavuus kehittäjän kannalta">
      <Section>
        <Lead>
          Tältä sivulta löydät saavutettavuusasioiden tarkistuslistan avuksi
          Työmarkkinatorin käyttöliittymien toteutukseen tai käytettäväksi esim.
          Definition of Done -tyyppisenä lopputarkistuksena sekä tarkempia
          teknisiä ohjeita usein toistuviin tilanteisiin.
        </Lead>
      </Section>

      <Section title="Kehittäjän tarkastuslista">
        <Collapsibles>
          <Collapsible
            isOpen={isOpen1}
            bordered
            label={
              <Heading
                level={3}
                noMargin
                size={4}
              >
                Varmista, että HTML on validia ja semanttisesti oikeanlaista
              </Heading>
            }
            onToggle={() => setIsOpen1(!isOpen1)}
          >
            <Paragraph>
              Hyvin toteutettu web-käyttöliittymä noudattaa HTML-standardia.
              Selaimet ja ruudunlukijat selviävät vaihtelevasti ei-validista
              koodista. Pahimmillaan ruudunlukija alkaa lukea koodia käyttäjälle
              tai ei pysty lukemaan elementtiä lainkaan.
            </Paragraph>
            <Paragraph>
              Semanttisesti oikein merkityt otsikot, listat ja linkit on
              mahdollista käydä läpi ruudunlukijalla, niistä saa nopeasti
              käsityksen, mikä sivulla on olennaista. Ilman semanttista
              merkkausta sokean käyttäjän saapuminen sivulle on tunnustelua yksi
              lause kerrallaan, mitä sivulta löytyy. Voit visualisoida tätä
              peittämällä paperilla selainikkunan ja siirtämällä paperia rivi
              kerrallaan alemmas.
            </Paragraph>
            <Paragraph noMargin>
              Reactissa suosi <Code>Fragment</Code>ia tai <Code>&lt;&gt;</Code>
              :ta <Code>div</Code>in sijaan, sillä tyypillisesti esim. taulukot
              ja listat voivat mennä rikki (ks.{' '}
              <Link
                external
                href="https://reactjs.org/docs/accessibility.html#semantic-html"
              >
                Reactin saavutettavuusohjeiden kohta Semantic HMTL
              </Link>
              ).
            </Paragraph>
          </Collapsible>

          <Collapsible
            isOpen={isOpen2}
            bordered
            label={
              <Heading
                level={3}
                noMargin
                size={4}
              >
                Testaa toiminnot näppäimistöllä
              </Heading>
            }
            onToggle={() => setIsOpen2(!isOpen2)}
          >
            <Paragraph noMargin>
              Testaa näppäimistöllä ainakin seuraavat asiat:
            </Paragraph>
            <List variant="unordered">
              <ListItem>
                Kokeile täyttää ja lähettää lomake täysin ilman hiirtä
              </ListItem>
              <ListItem>
                Selaa kaikki linkit/painikkeet läpi ja pohdi jokaisen kohdalla
                onko teksti ymmärrettävä, jos ei näe ympärillä olevia
                elementtejä. Kertooko nimi, mitä mitä toiminto tekee ja minne
                linkki vie?
              </ListItem>
              <ListItem>
                Testaa dialogiin siirtyminen, sen sisällä navigointi ja paluu
                takaisin sivulle. Fokuksen tulee säilyä dialogin sisällä, kunnes
                dialogi suljetaan. Dialogin sulkemisen jälkeen fokus palaa
                siihen, mistä dialogi avattiin.
              </ListItem>
            </List>

            <Paragraph>
              Saavutettavuuden kannalta on erittäin tärkeää testata, että kaikki
              sivun toiminnot on mahdollista suorittaa ilman hiirtä, pelkällä
              näppäimistöllä. Näppäimistöä käytettäessä esim. lomakkeen kentästä
              toiseen pääsee siirtymään sarkain- eli tab-näppäimellä. Olennaista
              on, että kaikki samat <Strong>lomakkeen toiminnot</Strong>{' '}
              onnistuvat niin näppäimistöllä kuin hiirellä.
            </Paragraph>
            <Paragraph>
              Joissakin tilanteissa saattaa olla yksittäisiä painikkeita, joita
              ei ole tarkoitettu näppäimistökäyttäjän käytettäväksi. Esim.
              hakukentässä voi olla nollauspainike, joka on piilotettu
              näppäimistöltä tabindexillä -1. Näppäimistökäyttäjä voi joka
              tapauksessa tyhjentää hakukentän näppäimistöä käyttäen ja
              hakukenttää seuraava looginen painike on hakupainike.
              Nollauspainike välissä hidastaisi käyttöä ja voisi aiheuttaa
              tahattomia virheitä.
            </Paragraph>

            <Paragraph noMargin>
              Linkkien tekstit on hyvä pitää lyhyinä. Ruudunlukuohjelmaa
              käyttävälle linkin tai painikkeen nimestä kuitenkin pitää käydä
              selväksi, mitä toiminto tekee (vrt. &quot;Lisää&quot; vs.
              &quot;Lisää työsuhde&quot;) tai mihin toiminto vie (esim. linkki
              ulkopuolisiin sivuihin). Näytöllä oleva teksti ja ruudunlukijan
              lukema teksti voivat myös erota toisistaan. Yleensä teksti on
              mahdollista muodostaa yhdistämällä olemassa olevia tekstejä.
              Neuvottele UX-suunnittelijan kanssa, miten teksti olisi parasta
              muodostaa. Tekniseen toteutukseen voit katsoa vinkit kohdasta{' '}
              <a href="#labelledby">Labelledby</a>
            </Paragraph>
          </Collapsible>

          <Collapsible
            isOpen={isOpen3}
            bordered
            label={
              <Heading
                level={3}
                noMargin
                size={4}
              >
                Tarkista Style Guide -komponentin ohjeet saavutettavuudesta
              </Heading>
            }
            onToggle={() => setIsOpen3(!isOpen3)}
          >
            <Paragraph noMargin>
              Style Guide -komponentit tehdään saavutettavuus huomioon ottaen.
              Tarkista mitä käytössäsi olevan komponentin saavutettavuudesta
              kerrotaan dokumentaatiossa. Osa saavutettavuuteen liittyvistä
              asioista on toteutettu valmiiksi, osa asioista täytyy määritellä
              tapauskohtaisesti. Kysy tarvittaessa apua Style Guide -kanavalla.
            </Paragraph>
          </Collapsible>

          <Collapsible
            isOpen={isOpen4}
            bordered
            label={
              <Heading
                level={3}
                noMargin
                size={4}
              >
                Tarkista otsikkotasojen hierarkia
              </Heading>
            }
            onToggle={() => setIsOpen4(!isOpen4)}
          >
            <Paragraph>
              Hyvä otsikkorakenne auttaa ruudunlukuohjelman käyttäjiä selaamaan
              sivua otsikkojen perusteella. Sivun pääsisällön tulee alkaa
              ensimmäisen tason (<Code>&lt;h1&gt;</Code>) otsikolla.
              Otsikkotasoja ei saa jättää välistä: otsikkotasoa{' '}
              <Code>&lt;h1&gt;</Code> tulee aina seurata <Code>&lt;h2&gt;</Code>{' '}
              eikä <Code>&lt;h3&gt;</Code>. Tekstiä ei tule tarpeettomasti
              merkitä otsikoksi, esim. leipätekstin suurentamiseksi.
            </Paragraph>
            <Paragraph>
              Kysy tarvittaessa UX-suunnittelijalta tarkennuksia, jos
              otsikkotasot eivät käy ilmi käyttöliittymäkuvista.
            </Paragraph>
            <Paragraph>
              Huomaa, että Style Guiden <Code>Heading</Code>-komponentin
              attribuutti <Code>level</Code> viittaa otsikon semanttiseen
              tasoon, <Code>size</Code> sen visuaaliseen kokoon.{' '}
              <Code>level</Code> on erityisen tärkeä merkitä, sillä se on
              olennaista tietoa ruudunlukijan käyttäjälle. Otsikon visuaalista
              kokoa <Code>size</Code> ei tarvitse erikseen määritellä, ellei se
              eroa semanttisesta tasosta. Erityisen haitallista on, jos otsikko
              muotoillaan näyttämään oikean kokoiselta pelkällä{' '}
              <Code>size</Code>lla. Jos <Code>level</Code>iä ei erikseen
              määritellä, sen oletusarvo on h1. Tällainen otsikko näyttää
              ruudulla oikean kokoiselta, mutta ruudunlukija lukee sen
              h1-otsikoksi.
            </Paragraph>
            <Paragraph noMargin>
              Tyypillinen hierarkia TE-Digissä (historiallisista syistä 2-tason
              kokoinen otsikko on harvinainen):
              <br />
              <Code>h1</Code>
              <br />
              <Code>h2 size=3</Code>
              <br />
              <Code>h3 size=4</Code>
              <br />
              <Code>h4 size=5</Code>
              <br />
              <Code>h5 size=6</Code>
              <br />
            </Paragraph>
          </Collapsible>

          <Collapsible
            isOpen={isOpen5}
            bordered
            label={
              <Heading
                level={3}
                noMargin
                size={4}
              >
                Anna palaute suoritetuista toiminnoista
              </Heading>
            }
            onToggle={() => setIsOpen5(!isOpen5)}
          >
            <Paragraph>
              Suoritetun toiminnon onnistumisesta tai epäonnistumisesta
              ilmoitetaan usein notifikaatiolla. Joissakin tapauksissa näkyvää
              ilmoitusta ei erikseen anneta, mutta ruudunlukijan käyttäjä voi
              silti hyötyä palautteesta, koska ei näe ruudulla tapahtuvia
              muutoksia. Tällöin voidaan käyttää piilotettua ilmoitusta (
              <Code>
                hidden <InnerLink page="Notifications" />
              </Code>
              ).
            </Paragraph>
            <Paragraph noMargin>
              Hakutoiminnallisuuksissa ruudunlukijalle tulisi antaa dynaamisesti
              tietoa, kun käyttäjä muokkaa hakua:
            </Paragraph>
            <List variant="unordered">
              <ListItem>
                Hakuehdon lisäämisestä annetaan piilotettu ilmoitus (
                <Code>
                  hidden <InnerLink page="Notifications" />
                </Code>
                ) &quot;Hakuehto x lisätty&quot;
              </ListItem>
              <ListItem>
                Hakutulosten päivittymisestä ilmoitetaan elementissä, jolla on{' '}
                <Code>aria-live</Code>-attribuutti. Ruudunlukija seuraa tällä
                attribuutilla merkittyjä elementtejä. Kun niiden sisältö
                muuttuu, ne luetaan ääneen. <Code>aria-live</Code> voi saada
                kaksi arvoa: <br />
                <Code>polite</Code> odottaa, että ruudunlukija saa aiemman
                tekstin luettua.
                <br />
                <Code>assertive</Code> keskeyttää ruudunlukijan ja lukee
                välittömästi muuttuneen tekstin.
              </ListItem>
            </List>
            <Help>
              <Paragraph noMargin>
                <Code>aria-live</Code>-attribuutilla merkitty elementti tulee
                olla valmiiksi lisättynä DOMiin. Jos elementti lisätään samalla
                hetkellä, kun sisältö muuttuu, ruudunlukija ei tunnista
                muutosta.
              </Paragraph>
            </Help>
            <Playground>
              <div
                id="announcement"
                aria-live="polite"
              >
                Löytyi 5 hakutulosta.
              </div>
            </Playground>
          </Collapsible>

          <Collapsible
            isOpen={isOpen6}
            bordered
            label={
              <Heading
                level={3}
                noMargin
                size={4}
              >
                Merkitse tarvittaessa fokusoitava elementti
              </Heading>
            }
            onToggle={() => setIsOpen6(!isOpen6)}
          >
            <Paragraph>
              Style Guide -komponentit ilmaisevat sinisellä kehyksellä, kun
              niillä on fokus. Visuaalinen kehys on olennainen käyttäjille,
              jotka näkevät ruudun mutta eivät käytä hiirtä, se kertoo missä
              kohtaa sivua ollaan menossa. Sivulle tullessa ruudunlukija lukee
              ensimmäisenä fokusoidun elementin. Oletuksena fokus on sivun
              alussa.
            </Paragraph>
            <Paragraph noMargin>
              Fokus täytyy erikseen asettaa mm. seuraavissa tapauksissa:
            </Paragraph>

            <List
              variant="unordered"
              noMargin
            >
              <ListItem>
                Sivulle ensimmäistä kertaa saavuttaessa näytetään evästeistä
                kertova banneri, fokus tulee siirtyä banneriin eikä sivun
                alkuun. Sitten kun banneri suljetaan, fokus tulee siirtyä sivun
                alkuun.
              </ListItem>
              <ListItem>
                Kun fokusoitu elementti poistuu esimerkiksi poisto-toimenpiteen
                seurauksena, fokus siirtyy oletuksena sivun alkuun.
                UX-suunnitelmasta pitäisi käydä ilmi, mihin fokus tulisi
                siirtyä.
              </ListItem>
              <ListItem>
                Kun avataan modaali, fokus tulee siirtyä modaaliin, mutta sieltä
                ei saa siirtyä ulos ennen kuin modaali suljetaan. Sitten kun
                modaali suljetaan, fokus tulisi tyypillisesti siirtyä siihen
                kohtaan, josta modaali avattiin.
              </ListItem>
              <ListItem>
                Siirryttäessä monisivuisella lomakkeella
                &quot;seuraava&quot;-painikkeella seuraavalle sivulle, fokus
                tulee siirtyä uuden lomakkeen alkuun (ei koko sivun alkuun,
                jolloin sivua aletaan lukea päänavigaatiosta alkaen)
              </ListItem>
            </List>
          </Collapsible>

          <Collapsible
            isOpen={isOpen7}
            bordered
            label={
              <Heading
                level={3}
                noMargin
                size={4}
              >
                Arvioi ikonien ja kuvien selitetekstien tarve
              </Heading>
            }
            onToggle={() => setIsOpen7(!isOpen7)}
          >
            <Paragraph>
              Ikoneilla on oletusarvoisesti <Code>role=presentation</Code>,
              jolloin niitä ei lueta ruudunlukijalla. Jos ikonilla halutaan
              välittää tietoa, joka ei ilmene tekstistä, voidaan sille
              tarvittaessa lisätä tekstivastine <Code>ariaLabel</Code>illä.
              Style Guiden komponentit huolehtivat sisältämiensä ikonien
              tekstivastineista.
            </Paragraph>
            <Paragraph>
              Suuremmille kuvituskuville (<Code>Image</Code>) on suositeltavaa
              tarjota tekstivastine <Code>alt</Code>. Tekstivastineessa ei ole
              tarpeen toistaa sitä faktaa, että kyseessä on kuva (&quot;kuva
              asiasta X&quot;). Kuvaile sen sijaan yhdellä tai kahdella
              lauseella tiiviisti se, mitä kuvassa näkyy ja mikä on kyseisessä
              yhteydessä olennaista.{' '}
              <Link
                external
                href="https://accessibility.huit.harvard.edu/describe-content-images"
              >
                Miten kirjoittaa hyvä alt-teksti (Harvardin yliopisto)
              </Link>
            </Paragraph>
            <Paragraph noMargin>
              Jos tekstivastinetta ei ole tarpeen määritellä, on parempi jättää
              attribuutin arvo tyhjäksi (<Code>alt=""</Code>) kuin jättää
              attribuutti kokonaan pois (koska ruudunlukija saattaa tällöin
              lukea esim. kuvatiedoston nimen).
            </Paragraph>
          </Collapsible>

          <Collapsible
            isOpen={isOpen8}
            bordered
            label={
              <Heading
                level={3}
                noMargin
                size={4}
              >
                Varmista, että lomakekentillä on label
              </Heading>
            }
            onToggle={() => setIsOpen8(!isOpen8)}
          >
            <Paragraph>
              Tyypillisin tapa on lisätä näkyvä nimilappu Style Guiden
              lomakekomponenteille on <Code>label</Code>-prop. Ruudunlukija
              lukee labelin lomakekenttään siirryttäessä. Joissakin
              erikoistapauksissa label täytyy syöttää{' '}
              <Code>
                <InnerLink page="Label" />
              </Code>
              -komponenttia käyttämällä ja linkata se lomakekenttään.
            </Paragraph>
            <Paragraph noMargin>
              Jos lomake-elementillä ei ole jostain syystä näkyvää otsikkoa,
              keskustele UX-suunnittelijan kanssa, millainen teksti sille
              määritellään ruudunlukijaa varten (käyttäen komponentin{' '}
              <Code>ariaLabel</Code>-proppia, joka lisää piilotetun labelin).
            </Paragraph>
          </Collapsible>

          <Collapsible
            isOpen={isOpen9}
            bordered
            label={
              <Heading
                level={3}
                noMargin
                size={4}
              >
                Huomioi esitys- ja etenemisjärjestys
              </Heading>
            }
            onToggle={() => setIsOpen9(!isOpen9)}
          >
            <Paragraph noMargin>
              Sivun sisältö luetaan ruudunlukijalla siinä järjestyksessä, kuin
              missä elementit ovat HTML-koodissa / DOMissa. Kannattaa testata
              myös, miten elementit asettuvat allekkain mobiilinäkymässä. Linkit
              ja painikkeet käydään läpi saman periaatteen mukaisesti (järjestys
              kannattaa testata tabilla). Jos esitysjärjestystä halutaan
              muuttaa, sen voi tehdä esim. css:n <Code>flex</Code>illä.
              Etenemisjärjestyksen voi määritellä <Code>tabindex</Code>illä.
            </Paragraph>
          </Collapsible>

          <Collapsible
            isOpen={isOpen10}
            bordered
            label={
              <Heading
                level={3}
                noMargin
                size={4}
              >
                Ilmoita komponentin tila
              </Heading>
            }
            onToggle={() => setIsOpen10(!isOpen10)}
          >
            <Paragraph noMargin>
              Avattavista ja suljettavista elementeistä pitää ilmoittaa, ovatko
              ne auki vai kiinni. Tyypillisesti tämä koskee haitareita ja
              menuja. Style Guiden komponenteissa tästä on huolehdittu.
            </Paragraph>
          </Collapsible>
          <Collapsible
            isOpen={isOpen11}
            bordered
            label={
              <Heading
                level={3}
                noMargin
                size={4}
              >
                Tarkista värikontrastit näkymässä
              </Heading>
            }
            onToggle={() => setIsOpen11(!isOpen11)}
          >
            <Paragraph>
              Tarkista, että tietoa ei välitetä pelkän värin avulla. Kaikkien
              normaalikokoisten tekstien kontrastisuhde taustaansa on oltava
              vähintään 4,5:1 ja suurikokoisten tekstien 3:1. Kaikkien muiden
              kuin tekstielementtien, joiden ymmärtäminen on tärkeää, kontrastin
              tulisi olla vähintään 3:1. Esimerkiksi neutral-7-värinen teksti ei
              täytä kontrastivaatimuksia, jos sen taustalla käytetään
              neutral-3-väriä (tai tummempaa).
            </Paragraph>
            <Paragraph>
              Kontrastin voit tarkistaa esimerkiksi selaimen lisäosalla Contrast
              Checker tai Chromen DevToolsin avulla.
            </Paragraph>
          </Collapsible>
        </Collapsibles>
      </Section>

      <Section title="Labelledby">
        <SectionHeading>
          <Inline gap="sm">
            <Bullet
              color="danger"
              icon={<ThumbDownIcon color="white" />}
              size="lg"
            />
            Tyypillinen ongelma
          </Inline>
        </SectionHeading>

        <Paragraph>
          Sivulla toistuu &quot;Muokkaa&quot;-niminen painike, mutta
          ruudunlukijakäyttäjän on vaikea hahmottaa, mihin kukin painike
          liittyy.
        </Paragraph>
        <Paragraph>
          Esimerkiksi sivulla voi olla otsikot &quot;Tiedot ja taidot&quot;,
          &quot;Esittely&quot; ja &quot;Työkokemus ja koulutus&quot; ja
          jokaiselle näistä on oma &quot;Muokkaa&quot;-painike. Täbillä
          siirryttäessä ruudunlukija lukee vain: Muokkaa painike, Muokkaa
          painike, Muokkaa painike
        </Paragraph>

        <SectionHeading>
          <Inline gap="sm">
            <Bullet
              color="success"
              icon={<ThumbUpIcon color="white" />}
              size="lg"
            />
            Korjaus
          </Inline>
        </SectionHeading>

        <Paragraph>
          Jokainen &quot;Muokkaa&quot;-painike liitetään ohjelmallisesti
          otsikkoon, johon ne liittyvät.
        </Paragraph>
        <Paragraph>
          Esimerkissä painikkeen <Code>ariaLabelledby</Code>-attribuuttiin on
          määritelty, minkä elementtien sisältö luetaan painikkeeseen
          siirryttäessä. Tässä tapauksessa <Code>ariaLabelledby</Code>lla on
          linkitetty painikkeen id sekä otsikon id. Ruudunlukija lukee: Muokkaa
          Tiedot ja taidot painike, Muokkaa Esittely painike, Muokkaa Työkokemus
          ja koulutus painike.
        </Paragraph>
        <Playground>
          <GridList md={3}>
            <Box>
              <Heading
                level={2}
                id="tiedot_ja_taidot"
              >
                Tiedot ja taidot
              </Heading>
              <Button
                id="muokkaabutton_1"
                ariaLabelledby="muokkaabutton_1 tiedot_ja_taidot"
              >
                Muokkaa
              </Button>
            </Box>

            <Box>
              <Heading
                level={2}
                id="esittely"
              >
                Esittely
              </Heading>
              <Button
                id="muokkaabutton_2"
                ariaLabelledby="muokkaabutton_2 esittely"
              >
                Muokkaa
              </Button>
            </Box>

            <Box>
              <Heading
                level={2}
                id="tyokokemus_ja_koulutus"
              >
                Työkokemus ja koulutus
              </Heading>
              <Button
                id="muokkaabutton_3"
                ariaLabelledby="muokkaabutton_3 tyokokemus_ja_koulutus"
              >
                Muokkaa
              </Button>
            </Box>
          </GridList>
        </Playground>
      </Section>

      <Section title="Virheen käsittely">
        <Paragraph>Esimerkki lomakkeen virheen käsittelystä.</Paragraph>
        <Playground example={CompleteFormExample} />
      </Section>
      <Section title="Aria-attribuutit">
        <Paragraph>
          Käytä seuraavassa järjestyksessä eli aria-describedby on ensisijainen
          suositus.
        </Paragraph>
        <List variant="ordered">
          <ListItem>Aria-describedby</ListItem>
          <ListItem>Aria-labelledby</ListItem>
          <ListItem>Aria-label</ListItem>
        </List>
        <SectionHeading>Aria-describedby</SectionHeading>
        <Paragraph>
          Aria-describedby-attribuuttia voidaan käyttää semanttisten
          HTML-elementtien ja elementtien kanssa, joilla on ARIA-rooli. Jos
          semanttista html-elementtiä ei ole, ruudunlukija ei osaa liittää
          elementtien id:ta toisiinsa ja tällöin ruudunlukijalle ei välity
          elementtien yhteys.
        </Paragraph>
        <Paragraph>
          Huomioi, että lomakkeella aria-describedby:lla liitetty sisältö
          luetaan viimeisenä ruudunlukijalla. Esimerkiksi lomakekentän sublabel
          luetaan lomakekentän jälkeen.
        </Paragraph>
        <Paragraph>Esimerkki käytöstä:</Paragraph>
        <Playground
          format="html"
          WrapperComponent={FormLayoutWrapper}
        >
          <div className="form-group mb-0">
            <LabelHTMLExample
              htmlFor="input-html-example-1"
              required
              subLabel="Sub Label"
              subLabelId="input-html-example-1-sublabel"
            >
              Label
            </LabelHTMLExample>
            <input
              aria-describedby="input-html-example-1-sublabel"
              className="form-control"
              id="input-html-example-1"
              aria-required
              type="text"
            />
          </div>
        </Playground>
        <Paragraph>
          Lisätietoa aria-describedby käytöstä:
          {''}{' '}
          <Link
            external
            href="https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-describedby"
          >
            Mdn web docs
          </Link>
        </Paragraph>
      </Section>
      <Section title="Aria-labelledby">
        <Paragraph>
          Aria-labelledby viittaa toiseen elementtiin niin, että saavutettava
          nimi on yhdistelmä linkitetyistä id:sta. Tämä attribuutti ei tarvitse
          semanttista elementtiä toimiakseen.
        </Paragraph>
        <Paragraph>Esimerkki käytöstä: </Paragraph>
        <Playground example={CollapsibleRequiredMissingExample} />
        <Playground>
          <>
            <Heading
              level={2}
              id="example_heading"
            >
              Otsikko
            </Heading>
            <Button
              id="example_button"
              ariaLabelledby="example_button example_heading"
            >
              Muokkaa
            </Button>
          </>
        </Playground>
        <Paragraph>
          Lisätietoa aria-labelledby käytöstä:
          {''}{' '}
          <Link
            external
            href="https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-labelledby"
          >
            Mdn web docs
          </Link>
        </Paragraph>
      </Section>
      <Section title="Aria-label">
        <Paragraph>
          Aria-labelia käytetään vuorovaikutteisessa elementissä (esim. button)
          tai elementissä, jolle on lisätty aria-rooli, joka tekee siitä
          vuorovaikutteisen.
        </Paragraph>
        <Paragraph>
          Aria-label tarkoittaa käytännössä luettavaa stringia. Käytä
          ensisijaisesti komponentin saavutettavaa nimeä, kuten painikkeen
          kohdalla painikkeen labelia. Jos tämä ei ole kuitenkaan riittävä,
          korvaa se aria-labelilla. Huomioi, että aria-label yliajaa elementin
          olemassaolevan labelin.
        </Paragraph>
        <Paragraph>
          Käytä aria-labelia vain, jos käyttöliittymässä ei ole linkitettävää
          elementtiä, jonka avulla saavutettava nimi saadaan.
        </Paragraph>
        <Paragraph>Esimerkki käytöstä:</Paragraph>
        <Playground>
          <Button ariaLabel="Muokkaa nimeä">Muokkaa</Button>
        </Playground>
        <Playground>
          <MailIcon ariaLabel="Sähköposti" />
        </Playground>
        <Paragraph>
          Lisätietoa aria-label käytöstä:
          {''}{' '}
          <Link
            external
            href="https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-label"
          >
            Mdn web docs
          </Link>
        </Paragraph>
      </Section>

      <Section title="Lisätietoa ja hyödyllisiä työkaluja">
        <List variant="unordered">
          <ListItem>
            <Link
              external
              href="https://www.a11yproject.com/checklist/"
            >
              Accessbility checklist
            </Link>
            kehittäjän näkökulmasta, HTML-elementtien mukaan jaoteltuna
          </ListItem>
          <ListItem>
            <Link
              external
              href="https://reactjs.org/docs/accessibility.html"
            >
              Reactin ohjeet saavutettavuuteen
            </Link>
          </ListItem>
          <ListItem>
            <Link
              external
              href="https://wave.webaim.org/extension/"
            >
              Wave
            </Link>
            -työkalu (selaimen lisäosa) on hyödyllinen sivuston rakenteen
            visualisointiin ja virheiden havaitsemiseen ilman ruudunlukijan
            käytön opettelua
          </ListItem>
          <ListItem>
            Käytetyin ruudunlukija on{' '}
            <Link
              external
              href="https://www.nvaccess.org/download/"
            >
              NVDA
            </Link>
          </ListItem>
        </List>
      </Section>
    </Content>
  )
}

export default Page
