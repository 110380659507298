import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  addNotification,
  Alert,
  Button,
  Checkbox,
  Col,
  FormGroup,
  FormLayout,
  FormLayoutItem,
  Heading,
  Input,
  Notifications,
  Row,
  Spacer
} from '@te-digi/styleguide'

interface FormData {
  firstName: string | undefined
  lastName: string | undefined
  nickname: string | undefined
  petName: string | undefined
  useNickname: boolean
}

const initialState = {
  firstName: undefined,
  lastName: undefined,
  nickname: undefined,
  petName: undefined,
  useNickname: false
}

const CompleteFormExample = () => {
  const [formData, setFormData] = useState<FormData>(initialState)
  const [firstNameError, setFirstNameError] = useState<string | undefined>(
    undefined
  )
  const [lastNameError, setLastNameError] = useState<string | undefined>(
    undefined
  )
  const [nicknameError, setNicknameError] = useState<string | undefined>(
    undefined
  )
  const [petNameError, setPetNameError] = useState<string | undefined>(
    undefined
  )
  const [formError, setFormError] = useState<string | undefined>(undefined)
  const [showFormError, setShowFormError] = useState(false)

  useEffect(() => {
    if (nicknameError && !formData.useNickname) {
      setNicknameError(undefined)
    }

    if (!firstNameError && !lastNameError && !nicknameError && !petNameError) {
      setFormError(undefined)
      setShowFormError(false)
    }

    if (firstNameError || lastNameError || nicknameError || petNameError) {
      setFormError(
        'Ilmoituksessasi on virheellisiä tai puutteellisia tietoja. Tarkasta ja korjaa tiedot. Et voi lähettää ilmoitustasi ennen kuin olet täyttänyt kaikki pakolliset tiedot.'
      )
    }
  }, [
    firstNameError,
    lastNameError,
    nicknameError,
    petNameError,
    setFormError,
    formData.useNickname
  ])

  const validateFormString = (
    target: string | undefined,
    requiredPrefix: string | undefined,
    capitalPrefix: string | undefined,
    errorSetter: (error: string | undefined) => void,
    force = false
  ): boolean => {
    if (target === undefined && !force) {
      errorSetter(undefined)
      return false
    } else if (requiredPrefix && (target === '' || target === undefined)) {
      errorSetter(requiredPrefix + ' on pakollinen')
      return true
    } else if (
      capitalPrefix &&
      target !== undefined &&
      target !== '' &&
      !RegExp('^[A-Z].*').exec(target)
    ) {
      errorSetter(capitalPrefix + ' on alettava isolla kirjaimella')
      return true
    } else {
      errorSetter(undefined)
      return false
    }
  }

  const handleSubmission = () => {
    let fisrNameErrorFound = false
    let lastNameErrorFound = false
    let nickNameErrorFound = false
    let petNameErrorFound = false

    fisrNameErrorFound = validateFormString(
      formData.firstName,
      'Etunimi',
      'Etunimen',
      setFirstNameError,
      true
    )

    lastNameErrorFound = validateFormString(
      formData.lastName,
      'Sukunimi',
      'Sukunimen',
      setLastNameError,
      true
    )

    if (formData.useNickname) {
      nickNameErrorFound = validateFormString(
        formData.nickname,
        'Kutsumanimi',
        'Kutsumanimen',
        setNicknameError,
        true
      )
    }

    petNameErrorFound = validateFormString(
      formData.petName,
      undefined,
      'Lemmikin nimen',
      setPetNameError
    )

    setShowFormError(
      fisrNameErrorFound ||
        lastNameErrorFound ||
        nickNameErrorFound ||
        petNameErrorFound
    )

    if (
      !fisrNameErrorFound &&
      !lastNameErrorFound &&
      !nickNameErrorFound &&
      !petNameErrorFound
    ) {
      addNotification(JSON.stringify(formData), { status: 'success' })
    }
  }

  return (
    <>
      <FormLayout>
        <FormLayoutItem
          header={
            <Heading
              level={2}
              size={2}
            >
              Nimitiedot
            </Heading>
          }
        >
          <Row>
            <Col>
              <FormGroup>
                <Input
                  error={firstNameError}
                  label={'Etunimi'}
                  onBlur={() =>
                    validateFormString(
                      formData.firstName,
                      'Etunimi',
                      'Etunimen',
                      setFirstNameError
                    )
                  }
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFormData({ ...formData, firstName: e.target.value })
                  }
                  required
                  subLabel={'Kirjoita etunimesi isolla alkukirjaimella'}
                  value={formData.firstName}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Input
                  error={lastNameError}
                  label={'Sukunimi'}
                  onBlur={() =>
                    validateFormString(
                      formData.lastName,
                      'Sukunimi',
                      'Sukunimen',
                      setLastNameError
                    )
                  }
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFormData({ ...formData, lastName: e.target.value })
                  }
                  required
                  subLabel={'Kirjoita sukunimesi isolla alkukirjaimella'}
                  value={formData.lastName}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Checkbox
                  checked={formData.useNickname}
                  name="checkbox-example-1"
                  onChange={() =>
                    setFormData({
                      ...formData,
                      useNickname: !formData.useNickname
                    })
                  }
                >
                  Ilmoita kutsumanimi
                </Checkbox>
              </FormGroup>
              {formData.useNickname && (
                <FormGroup>
                  <Input
                    error={nicknameError}
                    label={'Kutsumanimi'}
                    onBlur={() =>
                      validateFormString(
                        formData.nickname,
                        undefined,
                        'Kutsumanimen',
                        setNicknameError
                      )
                    }
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setFormData({ ...formData, nickname: e.target.value })
                    }
                    subLabel={'Kirjoita kutsumanimesi isolla alkukirjaimella'}
                    value={formData.nickname}
                  />
                </FormGroup>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Input
                  error={petNameError}
                  label={'Lemmikin nimi'}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFormData({ ...formData, petName: e.target.value })
                  }
                  subLabel={'Kirjoita lemmikkisi nimi isolla alkukirjaimella'}
                  value={formData.petName}
                />
              </FormGroup>
            </Col>
            <Col>
              <Spacer
                marginTop="lg"
                paddingTop="xl"
              >
                <Button
                  color={'secondary'}
                  onClick={() =>
                    validateFormString(
                      formData.petName,
                      undefined,
                      'Lemmikin nimen',
                      setPetNameError
                    )
                  }
                >
                  Tarkista lemminkin nimi
                </Button>
              </Spacer>
            </Col>
          </Row>

          {showFormError && <Alert color="danger">{formError}</Alert>}

          <Row justifyContent={'end'}>
            <Button
              color={'secondary'}
              onClick={handleSubmission}
              type="submit"
            >
              Lähetä
            </Button>
          </Row>
        </FormLayoutItem>
      </FormLayout>
      <Notifications />
    </>
  )
}

export { CompleteFormExample }
